const seasonsUrl = (existingUrl, id, seriesId) => {
  let url = existingUrl;
  if (seriesId) {
    url = url.replace(':id', seriesId);
  }
  if (id) {
    return url.replace(':seasonId', id);
  }
  return url;
};

export default seasonsUrl;
