/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Chip, Box, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import CELL_DATA_TYPES from '../../config/cell-types.config';
import { getPlatformLabel } from '../../config/platforms.config';
import { getAdminRoleLabel } from '../../config/admin-roles.config';
import {
  formatNumber,
  formatPercent,
  formatDuration,
  formatCurrency,
} from '../../utils/numbers.util';
import { formatDate, formatTimestamp } from '../../utils/datetime.util';
import StatusLabel from './status-label.component';
import PasswordLabel from './password-label.component';
import SubscriptionSku from './subscription-sku.component';
import Iconify from '../common/iconify.component';
import { SECONDARY } from '../../theme/theme-colors';
import { VIDEO_TYPE } from '../../config/const.config';

const CellValue = ({
  type,
  value,
  color,
  dataKey,
  cdnStatus,
  trailerCDNStatus,
  videoType,
}) => {
  if (type === CELL_DATA_TYPES.NUMBER) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatNumber(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.PERCENTAGE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatPercent(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.MONEY) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatCurrency(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DURATION) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDuration(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DATE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDate(value || null, 'll')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DATETIME) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDate(value || null, 'lll')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.TIMESTAMP) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatTimestamp(value || null, 'lll') || '-'}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.BOOL) {
    return (
      <Chip
        label={Number(value) === 1 ? 'Yes' : 'No'}
        color={Number(value) === 1 ? 'success' : 'error'}
        variant="filled"
        size="small"
        sx={{ fontWeight: 700 }}
      />
    );
  }

  if (type === CELL_DATA_TYPES.HTML) {
    return (
      <Box component="span" sx={{ display: 'block' }}>
        <Box
          dangerouslySetInnerHTML={{
            __html: value || '',
          }}
        />
      </Box>
    );
  }

  if (type === CELL_DATA_TYPES.LIKE || type === CELL_DATA_TYPES.DISLIKE) {
    return (
      <Box
        style={{
          cursor: 'pointer',
          width: 'fit-content',
        }}
      >
        <Iconify
          sx={{
            color: `${
              type === CELL_DATA_TYPES.LIKE
                ? Number(value) === 1
                  ? '#FFBD5A'
                  : 'transparent'
                : Number(value) === -1
                ? '#F00'
                : 'transparent'
            }`,
            stroke: `${
              type === CELL_DATA_TYPES.LIKE
                ? Number(value) !== 1 && '#000'
                : Number(value) !== -1 && '#000'
            }`,
          }}
          width={30}
          icon={`${
            type === CELL_DATA_TYPES.LIKE
              ? 'solar:like-bold'
              : 'solar:dislike-bold'
          }`}
        />
      </Box>
    );
  }
  if (type === CELL_DATA_TYPES.LIST_IMAGE) {
    if (value === null || value === '') {
      return <div />;
    }

    return (
      <Box
        component="img"
        sx={{
          height: 75,
          width: 75,
          objectFit: 'contain',
        }}
        src={value}
      />
    );
  }
  if (type === CELL_DATA_TYPES.VIEW_IMAGE) {
    if (value === null || value === '') {
      return <div />;
    }

    return (
      <Box
        component="img"
        sx={{
          height: 150,
          width: 150,
          objectFit: 'contain',
        }}
        src={value}
      />
    );
  }
  if (type === CELL_DATA_TYPES.PLATFORM) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getPlatformLabel(value || '')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.STATUS) {
    return <StatusLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.PASSWORD) {
    return <PasswordLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.CODE) {
    return (
      <Box
        component="span"
        sx={{
          whiteSpace: 'unset',
          wordBreak: 'break-all',
        }}
        color={color}
      >
        {value || ''}
      </Box>
    );
  }
  if (type === CELL_DATA_TYPES.ADMIN_ROLE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getAdminRoleLabel(value || '')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.SUBSCRIPTION_SKU) {
    return <SubscriptionSku value={value} />;
  }
  if (type === CELL_DATA_TYPES.PREVIEW) {
    if (dataKey === 'trailerPreview' && videoType !== VIDEO_TYPE[1]) {
      return <Box>-</Box>;
    }

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        {(dataKey === 'preview' && !cdnStatus) ||
        (dataKey === 'trailerPreview' && !trailerCDNStatus) ? (
          <Tooltip
            title={
              dataKey === 'trailerPreview'
                ? 'Please upload trailer'
                : 'Please upload video'
            }
            arrow
          >
            <Box display="flex" fontSize="20px" p={0} color="#FFBD5A">
              <Iconify icon="ri:alert-line" />
            </Box>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              (dataKey === 'preview' &&
                cdnStatus !== 'Finished' &&
                'Video still not sync from CDN') ||
              (dataKey === 'trailerPreview' &&
                trailerCDNStatus !== 'Finished' &&
                'Trailer still not sync from CDN')
            }
            arrow
          >
            <div>
              <IconButton
                aria-label="preview"
                disabled={
                  (dataKey === 'preview' && cdnStatus !== 'Finished') ||
                  (dataKey === 'trailerPreview' &&
                    trailerCDNStatus !== 'Finished')
                }
                sx={{ padding: 0, color: SECONDARY.dark }}
              >
                {dataKey === 'trailerPreview' ? (
                  <Iconify icon="bi:play-btn" />
                ) : (
                  <Iconify icon="solar:play-bold" />
                )}
              </IconButton>
            </div>
          </Tooltip>
        )}
      </Box>
    );
  }

  return (
    <Typography variant="body2" component="span" color={color}>
      {value && value}
      {!value &&
        (dataKey === 'subscriberFullName' ||
          dataKey === 'subscriberUsername') &&
        'Guest User'}
      {!value &&
        dataKey !== 'subscriberFullName' &&
        dataKey !== 'subscriberUsername' &&
        dataKey !== 'subscriberEmail' &&
        '-'}
    </Typography>
  );
};

CellValue.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  color: PropTypes.string,
  dataKey: PropTypes.string,
  cdnStatus: PropTypes.string,
  trailerCDNStatus: PropTypes.string,
  videoType: PropTypes.string,
};
CellValue.defaultProps = {
  value: null,
  color: 'inherit',
  dataKey: '',
  cdnStatus: '',
  trailerCDNStatus: '',
  videoType: '',
};

export default CellValue;
