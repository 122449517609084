import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Stack,
  Divider,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
  ExactLengthValidationFunc,
} from '../../utils/validations.util';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import Status from '../form-elements/status.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/videos.service';
import Datetimepicker from '../form-elements/datetimepicker.component';
import {
  convertDateStringToTimestamp,
  formatTimestampToUtc,
  getCurrentTimestamp,
} from '../../utils/datetime.util';
import ROUTE_PATH from '../../config/routes.config';
import Videos from '../form-elements/videos.component';
import VideoType from '../form-elements/video-types.component';
import Categories from '../form-elements/categories.component';
import { DEFAULT_END_TIME, VIDEO_TYPE } from '../../config/const.config';
import SKU from '../form-elements/sku.component';
import Series from '../form-elements/series.component';
import Seasons from '../form-elements/seasons.component';

const AddEditVideo = ({ dataId }) => {
  const defaultStart = formatTimestampToUtc(getCurrentTimestamp());
  const defaultEnd = formatTimestampToUtc(Number(DEFAULT_END_TIME));
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      title: '',
      shortDescription: '',
      description: '',
      videoType: 1,
      categories: [],
      series: null,
      seasons: null,
      avod: false,
      tvod: false,
      earlyAccess: false,
      start: null,
      end: null,
      earlyAccessStart: null,
      year: null,
      rating: null,
      cast: '',
      director: '',
      genre: '',
      writers: '',
      sku: null,
      earlyAccessSku: null,
      status: 1,
      portraitImage: '',
      portraitThumb: '',
      landscapeImage: '',
      landscapeThumb: '',
      trailerUrl: '',
    },
  });

  const videoTypeWatch = watch('videoType');
  const tvodWatch = watch('tvod');
  const earlyAccessWatch = watch('earlyAccess');
  const seriesWatch = watch('series');
  const startWatch = watch('start');
  const endWatch = watch('end');
  // const seasonsWatch = watch('seasons');

  const disabledEarlyAccessDateRef = useRef(false);
  const disabledVideoStartDateRef = useRef(false);
  const serverDataRef = useRef({
    isTvod: null,
    videoTitle: '',
  });
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const navigate = useNavigate();

  const handleActionSuccess = (message = '') => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    if (message) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message,
        });
      }, 10);
    }
  };

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        handleActionSuccess('New video created successfully.');

        navigate(ROUTE_PATH.CONTENT_VIDEOS);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        handleActionSuccess('Video details updated successfully.');
        navigate(ROUTE_PATH.CONTENT_VIDEOS);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const dateStartTimestamp = convertDateStringToTimestamp(data.start);
    const dateEndTimestamp = convertDateStringToTimestamp(data.end);
    const dateEarlyAccessStartTimestamp = convertDateStringToTimestamp(
      data.earlyAccessStart
    );
    if (
      dateStartTimestamp > 0 &&
      dateEndTimestamp > 0 &&
      dateStartTimestamp >= dateEndTimestamp
    ) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Video end date must be greater than video start date.',
        });
      }, 100);
      return;
    }
    if (
      videoTypeWatch === VIDEO_TYPE.MAIN &&
      tvodWatch &&
      earlyAccessWatch &&
      dateEarlyAccessStartTimestamp > 0 &&
      dateStartTimestamp > 0 &&
      dateEarlyAccessStartTimestamp >= dateStartTimestamp
    ) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Early access date must be lesser than video start date.',
        });
      }, 100);
      return;
    }

    if (data.videoType === VIDEO_TYPE.EPISODE && data.episodeNumber <= 0) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Episode Number value should be more than zero.',
        });
      }, 10);
      setFormSubmitted(false);
      return;
    }

    const selectedCategories = [];
    const selectedSeries = [];
    const selectedSeasons = [];
    if (videoTypeWatch === VIDEO_TYPE.MAIN) {
      for (let i = 0; i < data.categories.length; i += 1) {
        if (data.categories[i].id) {
          selectedCategories.push(data.categories[i].id);
        } else {
          selectedCategories.push(data.categories[i]);
        }
      }
    }
    if (videoTypeWatch === VIDEO_TYPE.EPISODE) {
      if (data.series.length > 0) {
        for (let i = 0; i < data.series.length; i += 1) {
          if (data.series[i].id) {
            selectedSeries.push(data.series[i].id);
          } else {
            selectedSeries.push(data.series[i]);
          }
        }
      } else if (data.series.id) {
        selectedSeries.push(data.series.id);
      } else {
        selectedSeries.push(data.series);
      }
    }
    if (videoTypeWatch === VIDEO_TYPE.EPISODE) {
      if (data.seasons.length > 0) {
        for (let i = 0; i < data.seasons.length; i += 1) {
          if (data.seasons[i].id) {
            selectedSeasons.push(data.seasons[i].id);
          } else {
            selectedSeasons.push(data.seasons[i]);
          }
        }
      } else if (data.seasons.id) {
        selectedSeasons.push(data.seasons.id);
      } else {
        selectedSeasons.push(data.seasons);
      }
    }

    const payload = {
      title: data.title,
      shortDescription: data.shortDescription || null,
      description: data.description || null,
      avod: data.avod && videoTypeWatch !== VIDEO_TYPE.CHOICE ? 1 : 0,
      tvod: data.tvod && videoTypeWatch !== VIDEO_TYPE.CHOICE ? 1 : 0,
      earlyAccess:
        data.earlyAccess && videoTypeWatch === VIDEO_TYPE.MAIN && data.tvod
          ? 1
          : 0,
      portraitImage: data.portraitImage || null,
      portraitThumb: data.portraitThumb || null,
      landscapeImage: data.landscapeImage || null,
      landscapeThumb: data.landscapeThumb || null,
      category: selectedCategories || [],
      series: selectedSeries[0] || null,
      seasons: selectedSeasons[0] || null,
      episodeNumber: Number(data.episodeNumber) || 0,
      trailerUrl: videoTypeWatch === VIDEO_TYPE.MAIN ? data.trailerUrl : '',
      start:
        data.start && videoTypeWatch !== VIDEO_TYPE.CHOICE
          ? dateStartTimestamp
          : null,
      end:
        data.end && videoTypeWatch !== VIDEO_TYPE.CHOICE
          ? dateEndTimestamp
          : null,
      earlyAccessStart:
        data.earlyAccessStart &&
        videoTypeWatch === VIDEO_TYPE.MAIN &&
        data.tvod &&
        data.earlyAccess
          ? dateEarlyAccessStartTimestamp
          : null,
      year: data.year || '',
      rating: data.rating,
      cast: data.cast,
      director: data.director,
      genre: data.genre,
      writers: data.writers,
      sku:
        videoTypeWatch !== VIDEO_TYPE.CHOICE && data.tvod ? data.sku?.id : null,
      earlyAccessSku:
        videoTypeWatch === VIDEO_TYPE.MAIN && data.tvod && data.earlyAccess
          ? data.earlyAccessSku?.id
          : null,
      status: data.status,
      videoType: data.videoType,
      mainVideoId:
        videoTypeWatch === VIDEO_TYPE.CHOICE ? data.mainVideo?.id : null,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        serverDataRef.current.isTvod = res.data?.tvod;
        serverDataRef.current.videoTitle = res.data?.title;

        const currentTime = getCurrentTimestamp();
        if (
          res.data.earlyAccessStart > 0 &&
          res.data.earlyAccessStart < currentTime
        ) {
          disabledEarlyAccessDateRef.current = true;
        }
        if (res.data.start > 0 && res.data.start < currentTime) {
          disabledEarlyAccessDateRef.current = true;
          disabledVideoStartDateRef.current = true;
        }

        // Fill form values
        setValue('title', res.data?.title);
        setValue('shortDescription', res.data?.shortDescription || '');
        setValue('description', res.data?.description || '');
        setValue('videoType', res.data?.videoType || '');
        setValue('categories', res.data?.category);
        setValue('seasons', res.data?.seasons);
        setValue('series', res.data?.series);
        setValue('episodeNumber', res.data?.episodeNumber);
        setValue(
          'mainVideo',
          { id: res.data?.mainVideoId, title: res.data?.mainVideoTitle } || ''
        );
        setValue('avod', res.data?.avod);
        setValue('tvod', res.data?.tvod);
        setValue('earlyAccess', res.data?.earlyAccess);
        setValue(
          'start',
          res.data.start ? formatTimestampToUtc(res.data?.start) : null
        );
        setValue(
          'end',
          res.data.end ? formatTimestampToUtc(res.data?.end) : null
        );
        setValue(
          'earlyAccessStart',
          res.data.earlyAccessStart
            ? formatTimestampToUtc(res.data?.earlyAccessStart)
            : null
        );
        setValue('year', res.data?.year || null);
        setValue('rating', res.data?.rating || null);
        setValue('cast', res.data?.cast || '');
        setValue('director', res.data?.director || '');
        setValue('genre', res.data?.genre || '');
        setValue('writers', res.data?.writers || '');
        setValue(
          'sku',
          (res.data?.skuId &&
            res.data?.sku &&
            res.data?.price && {
              id: res.data?.skuId,
              code: res.data?.sku,
              price: res.data?.price,
            }) ||
            null
        );
        setValue(
          'earlyAccessSku',
          (res.data?.earlyAccessSkuId &&
            res.data?.earlyAccessSku &&
            res.data?.earlyAccessSkuPrice && {
              id: res.data?.earlyAccessSkuId,
              code: res.data?.earlyAccessSku,
              price: res.data?.earlyAccessSkuPrice,
            }) ||
            null
        );
        setValue('status', res.data?.status || 0);
        setValue('portraitImage', res.data?.portraitImage || '');
        setValue('portraitThumb', res.data?.portraitThumb || '');
        setValue('landscapeImage', res.data?.landscapeImage || '');
        setValue('landscapeThumb', res.data?.landscapeThumb || '');
        setValue('trailerUrl', res.data?.trailerUrl || '');

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  useEffect(() => {
    if (videoTypeWatch === VIDEO_TYPE.EPISODE) {
      if (!startWatch) {
        setValue('start', defaultStart);
      }
      if (!endWatch) {
        setValue('end', defaultEnd);
      }
    } else {
      setValue('start', null);
      setValue('end', null);
    }
  }, [videoTypeWatch]);

  return (
    <>
      {loading && <Loading />}

      {!loading && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 1, py: 2 }}
          >
            <Box fontWeight="bold" fontSize="20px">
              {dataId
                ? `Edit Video Details - ${serverDataRef.current?.videoTitle}`
                : 'Add New Video'}
            </Box>
            <Box>
              <Link to={ROUTE_PATH.CONTENT_VIDEOS}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={formSubmitted}
                >
                  Back
                </Button>
              </Link>
            </Box>
          </Stack>
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...maxLengthValidation(200),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name *"
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="shortDescription"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...maxLengthValidation(255),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Short Description *"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.shortDescription}
                      helperText={errors?.shortDescription?.message || null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="videoType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <VideoType
                      id="videoType"
                      name="videoType"
                      label="Type"
                      defaultValue={value}
                      validOptions={[1, 2, 3]}
                      onChange={onChange}
                      disabled={dataId}
                      sx={{ width: '100%' }}
                      filterMain
                      error={errors?.videoType?.message || ''}
                    />
                  )}
                />
              </Grid>
              {videoTypeWatch === VIDEO_TYPE.EPISODE && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="series"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Series
                        id="series"
                        name="series"
                        label="Series *"
                        defaultValue={value}
                        multiple={false}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.series?.message || ''}
                        callback={() => setValue('seasons', null)}
                      />
                    )}
                  />
                </Grid>
              )}
              {videoTypeWatch === VIDEO_TYPE.EPISODE && seriesWatch && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="seasons"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Seasons
                        id="seasons"
                        name="seasons"
                        label="Seasons *"
                        defaultValue={value}
                        multiple={false}
                        onChange={(data) => {
                          if (data?.isPaid) {
                            setValue('tvod', true);
                          }
                          onChange(data);
                        }}
                        sx={{ width: '100%' }}
                        seriesId={seriesWatch}
                        error={errors?.seasons?.message || ''}
                      />
                    )}
                  />
                </Grid>
              )}
              {videoTypeWatch === VIDEO_TYPE.EPISODE && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="episodeNumber"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Episode Number *"
                        type="number"
                        autoComplete="off"
                        fullWidth
                        variant="filled"
                        error={!!errors.episodeNumber}
                        helperText={errors?.episodeNumber?.message || null}
                      />
                    )}
                  />
                </Grid>
              )}
              {videoTypeWatch === VIDEO_TYPE.MAIN && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="categories"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Categories
                        id="categories"
                        name="categories"
                        label="Categories *"
                        defaultValue={value}
                        multiple
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.categories?.message || ''}
                      />
                    )}
                  />
                </Grid>
              )}
              {videoTypeWatch === VIDEO_TYPE.CHOICE && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="mainVideo"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Videos
                        id="mainVideo"
                        name="mainVideo"
                        label="Main Video *"
                        defaultValue={value}
                        multiple={false}
                        disabled={dataId}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        filterMain
                        filterWithoutFree
                        error={errors?.mainVideo?.message || ''}
                      />
                    )}
                  />
                </Grid>
              )}
              {videoTypeWatch !== VIDEO_TYPE.CHOICE && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="avod"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={value}
                              onChange={onChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                              color="primary"
                            />
                          }
                          label="AVOD"
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={tvodWatch ? 3 : 6}
                    md={tvodWatch ? 3 : 6}
                  >
                    <Controller
                      name="tvod"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={value}
                              onChange={onChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                              color="primary"
                              disabled={
                                serverDataRef.current?.isTvod &&
                                disabledVideoStartDateRef.current
                              }
                            />
                          }
                          label="TVOD"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {videoTypeWatch !== VIDEO_TYPE.CHOICE &&
                (tvodWatch === true || tvodWatch === 1) && (
                  <>
                    {videoTypeWatch === VIDEO_TYPE.MAIN ? (
                      <Grid item xs={12} sm={3} md={3}>
                        <Controller
                          name="earlyAccess"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={value}
                                  onChange={onChange}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                  color="primary"
                                  disabled={disabledVideoStartDateRef.current}
                                />
                              }
                              label="Early Access"
                            />
                          )}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={3} md={3} />
                    )}
                    <Grid item xs={12} sm={6} md={6}>
                      <Controller
                        name="sku"
                        control={control}
                        rules={validationRules.REQUIRED}
                        render={({ field: { onChange, value } }) => (
                          <SKU
                            id="sku"
                            name="sku"
                            label="Sku *"
                            defaultValue={value}
                            onChange={onChange}
                            sx={{ width: '100%' }}
                            error={errors?.sku?.message || ''}
                          />
                        )}
                      />
                    </Grid>
                    {!earlyAccessWatch &&
                      videoTypeWatch !== VIDEO_TYPE.EPISODE && (
                        <Grid item xs={12} sm={6} md={6} />
                      )}

                    {(earlyAccessWatch === 1 || earlyAccessWatch === true) &&
                      videoTypeWatch !== VIDEO_TYPE.EPISODE && (
                        <>
                          <Grid item xs={12} sm={6} md={6}>
                            <Controller
                              name="earlyAccessSku"
                              control={control}
                              rules={validationRules.REQUIRED}
                              render={({ field: { onChange, value } }) => (
                                <SKU
                                  id="earlyAccessSku"
                                  name="earlyAccessSku"
                                  label="Early Access Sku *"
                                  defaultValue={value}
                                  onChange={onChange}
                                  sx={{ width: '100%' }}
                                  error={errors?.earlyAccessSku?.message || ''}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Controller
                              name="earlyAccessStart"
                              control={control}
                              rules={validationRules.REQUIRED}
                              render={({ field: { onChange, value } }) => (
                                <Datetimepicker
                                  id="earlyAccessStartDate"
                                  name="earlyAccessStartDate"
                                  label="Early Access Date *"
                                  defaultValue={value}
                                  clearable
                                  onChange={onChange}
                                  sx={{ width: '100%' }}
                                  error={
                                    errors?.earlyAccessStart?.message || ''
                                  }
                                  disabled={disabledVideoStartDateRef.current}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} />
                        </>
                      )}
                  </>
                )}
              {videoTypeWatch !== VIDEO_TYPE.CHOICE && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="start"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <Datetimepicker
                          id="video_start_date"
                          name="video_start_date"
                          label="Video Start Date *"
                          defaultValue={value}
                          clearable
                          onChange={onChange}
                          sx={{ width: '100%' }}
                          error={errors?.start?.message || ''}
                          disabled={
                            serverDataRef.current?.isTvod &&
                            disabledVideoStartDateRef.current
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="end"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <Datetimepicker
                          id="video_end_date"
                          name="video_end_date"
                          label="Video End Date *"
                          defaultValue={value}
                          clearable
                          onChange={onChange}
                          sx={{ width: '100%' }}
                          error={errors?.end?.message || ''}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="year"
                  control={control}
                  rules={ExactLengthValidationFunc(4)}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Year"
                      type="number"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.year}
                      helperText={errors?.year?.message || null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="rating"
                  control={control}
                  rules={maxLengthValidation(50)}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Rating"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.rating}
                      helperText={errors?.rating?.message || null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="cast"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Cast"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="director"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Director"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="genre"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Genre"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="writers"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Writers"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="video_status"
                      name="video_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Divider
                  textAlign="center"
                  variant="fullWidth"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  Video Details (You can upload video and images from upload
                  section)
                </Divider>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="portraitImage"
                  control={control}
                  rules={validationRules.OPTIONAL_URL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Portrait"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.portraitImage}
                      helperText={errors?.portraitImage?.message || null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="portraitThumb"
                  control={control}
                  rules={validationRules.OPTIONAL_URL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Portrait Thumb"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.portraitThumb}
                      helperText={errors?.portraitThumb?.message || null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="landscapeImage"
                  control={control}
                  rules={validationRules.OPTIONAL_URL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Landscape"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.landscapeImage}
                      helperText={errors?.landscapeImage?.message || null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="landscapeThumb"
                  control={control}
                  rules={validationRules.OPTIONAL_URL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Landscape Thumb"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.landscapeThumb}
                      helperText={errors?.landscapeThumb?.message || null}
                    />
                  )}
                />
              </Grid>
              {videoTypeWatch === VIDEO_TYPE.MAIN && (
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="trailerUrl"
                    control={control}
                    rules={validationRules.OPTIONAL_URL}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Trailer Url"
                        type="text"
                        autoComplete="off"
                        fullWidth
                        variant="filled"
                        error={!!errors.trailerUrl}
                        helperText={errors?.trailerUrl?.message || null}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </form>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ py: 2.5 }}
          >
            <div>
              <Button
                type="submit"
                form="add-edit-form"
                color="primary"
                variant="contained"
                disabled={loading || formSubmitted}
                sx={{ marginRight: 2 }}
              >
                Save
              </Button>
              <Link to={ROUTE_PATH.CONTENT_VIDEOS}>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={formSubmitted}
                >
                  Cancel
                </Button>
              </Link>
            </div>
            <Grid>
              <Typography variant="body2" color="secondary">
                * indicates a required field
              </Typography>
            </Grid>
          </Stack>
        </>
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

AddEditVideo.propTypes = {
  dataId: PropTypes.number.isRequired,
};

export default AddEditVideo;
