import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Videos module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Name',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'landscapeImage',
    label: 'Landscape Image',
    width: '5%',
    align: 'left',
    dataKey: 'landscapeImage',
    sortable: false,
    type: CELL_DATA_TYPES.LIST_IMAGE,
  },
  {
    id: 'portraitImage',
    label: 'Portrait Image',
    width: '5%',
    align: 'left',
    dataKey: 'portraitImage',
    sortable: false,
    type: CELL_DATA_TYPES.LIST_IMAGE,
  },
  {
    id: 'isPaid',
    label: 'TVOD',
    align: 'left',
    dataKey: 'isPaid',
    sortable: true,
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'start',
    label: 'Season Start Date',
    align: 'left',
    dataKey: 'start',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'end',
    label: 'Season End Date',
    align: 'left',
    dataKey: 'end',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'seasonNumber',
    label: 'Season Number',
    width: '5%',
    align: 'left',
    dataKey: 'seasonNumber',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'left',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_ASSETS.value,
    label: ACTIONS.UPLOAD_ASSETS.label,
  },
  {
    action: ACTIONS.VIEW_EPISODES.value,
    label: ACTIONS.VIEW_EPISODES.label,
  },
  {
    action: ACTIONS.GEO_RESTRICTION.value,
    label: ACTIONS.GEO_RESTRICTION.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'title',
    label: 'Name',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isPaid',
    label: 'TVOD',
    dataKey: 'isPaid',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'earlyAccess',
    label: 'Early Access',
    dataKey: 'earlyAccess',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'landscapeImage',
    label: 'Landscape Image',
    dataKey: 'landscapeImage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'landscapeThumb',
    label: 'Landscape Thumb Image',
    dataKey: 'landscapeThumb',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'portraitImage',
    label: 'Portrait Image',
    dataKey: 'portraitImage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'portraitThumb',
    label: 'Portrait Thumb Image',
    dataKey: 'portraitThumb',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'earlyAccessStart',
    label: 'Early Access Start Date',
    dataKey: 'earlyAccessStart',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'start',
    label: 'Start Date',
    dataKey: 'start',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'end',
    label: 'End Date',
    dataKey: 'end',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'sku',
    label: 'SKU',
    dataKey: 'sku',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'earlyAccessSku',
    label: 'Early Access SKU',
    dataKey: 'earlyAccessSku',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'price',
    label: 'Price',
    dataKey: 'price',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'earlyAccessSkuPrice',
    label: 'Early Access Price',
    dataKey: 'earlyAccessSkuPrice',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'seasonNumber',
    label: 'Season Number',
    dataKey: 'seasonNumber',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified At',
    dataKey: 'modifiedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
