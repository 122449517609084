import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { maxLengthValidation } from '../../utils/validations.util';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById, viewDataById } from '../../services/youtube.service';

const AddEditYoutubeLink = ({ onSuccess }) => {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      link: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const updateExistingData = (payload) => {
    updateDataById(payload)
      .then(() => {
        setFormSubmitted(false);
        onSuccess('Youtube Link Save Successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = {
      link: data.link,
    };
    updateExistingData(payload);
  };

  useEffect(() => {
    viewDataById()
      .then((res) => {
        if (res.data === null) {
          setLoading(false);
        } else {
          // Fill form values
          setValue('link', res.data[0]?.link || '');
          setLoading(false);
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading && <Loading />}

      {!loading && (
        <>
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="link"
                  control={control}
                  rules={{
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Link"
                      type="text"
                      fullWidth
                      inputProps={{ maxLength: 500 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ mt: 3 }}
          >
            Save
          </Button>
        </>
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

AddEditYoutubeLink.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditYoutubeLink;
