import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Series module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Name',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'landscapeImage',
    label: 'Landscape Image',
    width: '5%',
    align: 'left',
    dataKey: 'landscapeImage',
    sortable: false,
    type: CELL_DATA_TYPES.LIST_IMAGE,
  },
  {
    id: 'portraitImage',
    label: 'Portrait Image',
    width: '5%',
    align: 'left',
    dataKey: 'portraitImage',
    sortable: false,
    type: CELL_DATA_TYPES.LIST_IMAGE,
  },
  {
    id: 'seasons',
    label: 'Season Count',
    width: '5%',
    align: 'left',
    dataKey: 'seasons',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'startDate',
    label: 'Series Start Date',
    align: 'left',
    dataKey: 'startDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'endDate',
    label: 'Series End Date',
    align: 'left',
    dataKey: 'endDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'left',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_ASSETS.value,
    label: ACTIONS.UPLOAD_ASSETS.label,
  },
  {
    action: ACTIONS.MANAGE_SEASONS.value,
    label: ACTIONS.MANAGE_SEASONS.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'title',
    label: 'Name',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'seasons',
    label: 'Seasons Count',
    dataKey: 'seasons',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'landscapeImage',
    label: 'Landscape Image',
    dataKey: 'landscapeImage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'landscapeThumb',
    label: 'Landscape Thumb Image',
    dataKey: 'landscapeThumb',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'portraitImage',
    label: 'Portrait Image',
    dataKey: 'portraitImage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'portraitThumb',
    label: 'Portrait Thumb Image',
    dataKey: 'portraitThumb',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'start',
    label: 'Start Date',
    dataKey: 'start',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'end',
    label: 'End Date',
    dataKey: 'end',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified At',
    dataKey: 'modifiedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
