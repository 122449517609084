import React from 'react';
import { useParams } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import ListSeasons from '../../components/seasons/list-seasons.component';

const ContentSeasonsView = () => {
  const { id } = useParams();
  const pageTitle = 'Content / Seasons';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSeasons dataId={id || 0} />
    </AppLayout>
  );
};

export default ContentSeasonsView;
