import React, { useState } from 'react';
import AddEditYoutubeLink from './add-edit-youtube-link.component';
import SnackbarInfo from '../common/snackbar-info.component';

const ListYoutubeLink = () => {
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleActionSuccess = (message = '') => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    if (message) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message,
        });
      }, 50);
    }
  };

  return (
    <>
      <AddEditYoutubeLink onSuccess={handleActionSuccess} />

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListYoutubeLink;
