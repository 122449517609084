import React from 'react';
import { useParams } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import AddEditSeries from '../../components/series/add-edit-series.component';

const ContentSeriesAddEdit = () => {
  const { id } = useParams();
  const pageTitle = 'Content / Series';

  return (
    <AppLayout pageTitle={pageTitle}>
      <AddEditSeries dataId={id || 0} />
    </AppLayout>
  );
};

export default ContentSeriesAddEdit;
