import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const updateDataById = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/youtube/video/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
export const viewDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/youtube/video/${id}/view`,
    'GET',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
