import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListSeries from '../../components/series/list-series.component';

const ContentSeriesView = () => {
  const pageTitle = 'Content / Series';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSeries />
    </AppLayout>
  );
};

export default ContentSeriesView;
