import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  Divider,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import Status from '../form-elements/status.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/series.service';
import ROUTE_PATH from '../../config/routes.config';
import Datetimepicker from '../form-elements/datetimepicker.component';
import {
  convertDateStringToTimestamp,
  formatTimestampToUtc,
  getCurrentTimestamp,
} from '../../utils/datetime.util';
import { DEFAULT_END_TIME } from '../../config/const.config';

const AddEditSeries = ({ dataId }) => {
  const defaultStart = formatTimestampToUtc(getCurrentTimestamp());
  const defaultEnd = formatTimestampToUtc(Number(DEFAULT_END_TIME));
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      status: 1,
      portraitImage: '',
      portraitThumb: '',
      landscapeImage: '',
      landscapeThumb: '',
      start: defaultStart,
      end: defaultEnd,
    },
  });
  const serverDataRef = useRef({
    isTvod: null,
    seriesTitle: '',
  });
  const disabledVideoStartDateRef = useRef(false);

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const navigate = useNavigate();

  const handleActionSuccess = (message = '') => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    if (message) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message,
        });
      }, 10);
    }
  };

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        handleActionSuccess('New series created successfully.');

        navigate(ROUTE_PATH.CONTENT_SERIES);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        handleActionSuccess('Series details updated successfully.');
        navigate(ROUTE_PATH.CONTENT_SERIES);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const dateStartTimestamp = convertDateStringToTimestamp(data.start);
    const dateEndTimestamp = convertDateStringToTimestamp(data.end);
    const payload = {
      title: data.title,
      description: data.description || null,
      portraitImage: data.portraitImage || null,
      portraitThumb: data.portraitThumb || null,
      landscapeImage: data.landscapeImage || null,
      landscapeThumb: data.landscapeThumb || null,
      start: data.start ? dateStartTimestamp : defaultStart,
      end: data.end ? dateEndTimestamp : defaultEnd,
      status: data.status,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        serverDataRef.current.seriesTitle = res.data?.title;
        const currentTime = getCurrentTimestamp();
        if (res.data.start > 0 && res.data.start < currentTime) {
          disabledVideoStartDateRef.current = true;
        }
        // Fill form values
        setValue('title', res.data?.title);
        setValue('description', res.data?.description || '');

        setValue('status', res.data?.status || 0);
        setValue('portraitImage', res.data?.portraitImage || '');
        setValue('portraitThumb', res.data?.portraitThumb || '');
        setValue('landscapeImage', res.data?.landscapeImage || '');
        setValue('landscapeThumb', res.data?.landscapeThumb || '');
        setValue(
          'start',
          res.data.start ? formatTimestampToUtc(res.data?.start) : defaultStart
        );
        setValue(
          'end',
          res.data.end ? formatTimestampToUtc(res.data?.end) : defaultEnd
        );

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <>
      {loading && <Loading />}

      {!loading && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 1, py: 2 }}
          >
            <Box fontWeight="bold" fontSize="20px">
              {dataId
                ? `Edit Series Details - ${serverDataRef.current?.seriesTitle}`
                : 'Add New series'}
            </Box>
            <Box>
              <Link to={ROUTE_PATH.CONTENT_SERIES}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={formSubmitted}
                >
                  Back
                </Button>
              </Link>
            </Box>
          </Stack>
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...maxLengthValidation(200),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name *"
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="series_status"
                      name="series_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    ...maxLengthValidation(255),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      autoComplete="off"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="start"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="series_start_date"
                      name="series_start_date"
                      label="Series Start Date *"
                      defaultValue={value}
                      clearable
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.start?.message || ''}
                      disabled={
                        serverDataRef.current?.isTvod &&
                        disabledVideoStartDateRef.current
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="end"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="series_end_date"
                      name="series_end_date"
                      label="Series End Date *"
                      defaultValue={value}
                      clearable
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.end?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Divider
                  textAlign="center"
                  variant="fullWidth"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  Series Details (You can upload series and images from upload
                  section)
                </Divider>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="portraitImage"
                  control={control}
                  rules={validationRules.OPTIONAL_URL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Portrait"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.portraitImage}
                      helperText={errors?.portraitImage?.message || null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="portraitThumb"
                  control={control}
                  rules={validationRules.OPTIONAL_URL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Portrait Thumb"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.portraitThumb}
                      helperText={errors?.portraitThumb?.message || null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="landscapeImage"
                  control={control}
                  rules={validationRules.OPTIONAL_URL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Landscape"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.landscapeImage}
                      helperText={errors?.landscapeImage?.message || null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="landscapeThumb"
                  control={control}
                  rules={validationRules.OPTIONAL_URL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Landscape Thumb"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      error={!!errors.landscapeThumb}
                      helperText={errors?.landscapeThumb?.message || null}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ py: 2.5 }}
          >
            <div>
              <Button
                type="submit"
                form="add-edit-form"
                color="primary"
                variant="contained"
                disabled={loading || formSubmitted}
                sx={{ marginRight: 2 }}
              >
                Save
              </Button>
              <Link to={ROUTE_PATH.CONTENT_SERIES}>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={formSubmitted}
                >
                  Cancel
                </Button>
              </Link>
            </div>
            <Grid>
              <Typography variant="body2" color="secondary">
                * indicates a required field
              </Typography>
            </Grid>
          </Stack>
        </>
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

AddEditSeries.propTypes = {
  dataId: PropTypes.number.isRequired,
};

export default AddEditSeries;
