import React, { useState } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Typography,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Iconify from '../common/iconify.component';
import Sortable from './sortable.component';
import NoData from './no-data.component';
import Error from './error.component';
import Loading from './loading.component';
import CellValue from './cell-value.component';
import CELL_DATA_TYPES from '../../config/cell-types.config';
import { videoReview } from '../../services/videos.service';

const ListData = ({
  columns,
  rows,
  page,
  rowsPerPage,
  totalRows,
  loading,
  actions,
  error,
  sortBy,
  sortOrder,
  onPageChange,
  onRowsPerPageChange,
  onSortChange,
  onAction,
  onPreviewClick,
  // fromPage,
  updateState,
}) => {
  const colSpan = columns.length + (actions.length ? 1 : 0);
  const { id } = useParams();

  const [anchorActionEl, setAnchorActionEl] = useState(null);
  const [openActions, setOpenActions] = useState({
    show: false,
    data: null,
  });
  // const [renderedRows, setRenderedRows] = useState(rows);

  const handleActionOpen = (e, selectedRow) => {
    setAnchorActionEl(e.currentTarget);
    setOpenActions({
      ...openActions,
      show: true,
      data: selectedRow,
    });
  };
  const handleActionClose = () => {
    setAnchorActionEl(null);
    setOpenActions({
      ...openActions,
      show: false,
      data: null,
    });
  };
  const handleActionSelect = (selectedAction) => {
    onAction(selectedAction, openActions.data);
    handleActionClose();
  };
  const handleSingleActionSelect = (data) => {
    onAction(actions[0].action, data);
    handleActionClose();
  };

  // const updateState = (updatedId, newType) => {
  //   const newRows = [];
  //   for (let i = 0; i < renderedRows.length; i += 1) {
  //     let tmpRow = renderedRows[i];
  //     if (tmpRow.subscriberId === updatedId) {
  //       tmpRow = {
  //         ...tmpRow,
  //         type: newType,
  //       };
  //     }
  //     newRows.push(tmpRow);
  //   }
  //   setRenderedRows(newRows);
  // };

  // useEffect(() => {
  //   if (fromPage !== 'regular') setRenderedRows(rows);
  // }, [rows]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((c) => (
                <TableCell
                  key={`col-${c.id}`}
                  align={c.align}
                  width={c.width}
                  sx={{
                    cursor: c.sortable ? 'pointer' : 'inherit',
                  }}
                  onClick={() => {
                    if (c.sortable) {
                      if (c.dataKey === sortBy) {
                        if (sortOrder === '') {
                          onSortChange(c.dataKey, 'asc');
                        } else if (sortOrder === 'asc') {
                          onSortChange(c.dataKey, 'desc');
                        } else if (sortOrder === 'desc') {
                          onSortChange('', '');
                        }
                      } else {
                        onSortChange(c.dataKey, 'asc');
                      }
                    }
                  }}
                >
                  <Stack direction="row" justifyContent={c.align}>
                    {c.sortable && (
                      <Sortable
                        dataKey={c.dataKey}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                      />
                    )}
                    {c.label}
                  </Stack>
                </TableCell>
              ))}

              {actions.length > 0 && (
                <TableCell align="right" width="5%">
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading && !error && (
              <>
                {/* {(fromPage === 'regular' ? rows : renderedRows).map( */}
                {rows.map((r, index) => (
                  <TableRow key={`row-${r[columns[0].dataKey]}-${index}`}>
                    {columns.map((c) => (
                      <TableCell
                        key={`data-col-${c.id}-${r[c.dataKey]}`}
                        align={c.align}
                        width={c.width}
                        onClick={() => {
                          if (
                            c.dataKey === 'preview' &&
                            (r.videoUrl || r.adUrl) &&
                            r.cdnStatus === 'Finished'
                          ) {
                            onPreviewClick(r, 'video');
                          } else if (
                            c.dataKey === 'trailerPreview' &&
                            r.trailerUrl &&
                            r.trailerCDNStatus === 'Finished'
                          ) {
                            onPreviewClick(r, 'trailer');
                          } else if (
                            c.type === CELL_DATA_TYPES.LIKE ||
                            c.type === CELL_DATA_TYPES.DISLIKE
                          ) {
                            let value = 0;
                            if (
                              c.type === CELL_DATA_TYPES.LIKE &&
                              r.type !== 1
                            ) {
                              value = 1;
                            } else if (
                              c.type === CELL_DATA_TYPES.DISLIKE &&
                              r.type !== -1
                            ) {
                              value = -1;
                            }
                            const payload = {
                              type: value,
                              subscriberId: r.subscriberId,
                            };
                            videoReview(id, payload)
                              .then((result) => {
                                updateState(r.subscriberId, result.data);
                              })
                              .catch(() => {});
                          }
                        }}
                        style={{
                          cursor: `${
                            c.type === CELL_DATA_TYPES.LIKE ||
                            c.type === CELL_DATA_TYPES.DISLIKE
                          }`,
                        }}
                      >
                        <CellValue
                          type={c.type}
                          value={
                            c.type === CELL_DATA_TYPES.LIKE ||
                            c.type === CELL_DATA_TYPES.DISLIKE
                              ? r[c.dataKey] || r.type
                              : r[c.dataKey]
                          }
                          dataKey={c.dataKey}
                          cdnStatus={r.cdnStatus}
                          trailerCDNStatus={r.trailerCDNStatus}
                          videoType={r.videoType}
                        />

                        {c.secondaryFields && (
                          <>
                            {c.secondaryFields.map((sf, sfIdx) => {
                              if (r[sf.dataKey])
                                return (
                                  <React.Fragment
                                    key={`sf-data-col-${c.id}-${sfIdx}`}
                                  >
                                    <br />
                                    <CellValue
                                      type={sf.type}
                                      value={r[sf.dataKey]}
                                      color="secondary"
                                      dataKey={sf.dataKey}
                                    />
                                  </React.Fragment>
                                );
                              return null;
                            })}
                          </>
                        )}
                      </TableCell>
                    ))}

                    {actions.length > 1 && (
                      <TableCell align="right" width="5%">
                        <IconButton
                          size="small"
                          color="inherit"
                          onClick={(e) => handleActionOpen(e, r)}
                        >
                          <Iconify icon="eva:more-vertical-fill" />
                        </IconButton>
                      </TableCell>
                    )}

                    {actions.length === 1 && (
                      <TableCell align="right" width="5%">
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() => handleSingleActionSelect(r)}
                        >
                          {actions[0].label}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </>
            )}

            {!loading && !error && totalRows === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={colSpan}>
                  <NoData />
                </TableCell>
              </TableRow>
            )}

            {!loading && error && totalRows === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={colSpan}>
                  <Error />
                </TableCell>
              </TableRow>
            )}

            {loading && (
              <TableRow>
                <TableCell align="center" colSpan={colSpan}>
                  <Loading />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!loading && totalRows > 0 && (
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={(e, newPage) => onPageChange(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(Number(e.target.value));
          }}
        />
      )}

      {openActions.show && (
        <Menu
          id="actions-menu"
          anchorEl={anchorActionEl}
          open
          onClose={() => handleActionClose()}
          size="small"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {actions.map((a, index) => {
            let render = true;
            if (a.conditional) {
              render = a.conditional(openActions.data);
            }

            if (render) {
              return (
                <MenuItem
                  key={`mia-${a.action}-${index}`}
                  onClick={() => handleActionSelect(a.action)}
                >
                  <Typography variant="body2">{a.label}</Typography>
                </MenuItem>
              );
            }

            return null;
          })}
        </Menu>
      )}
    </>
  );
};

ListData.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  error: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  onPreviewClick: PropTypes.func,
  updateState: PropTypes.func,
  // fromPage: PropTypes.string,
};
ListData.defaultProps = {
  onAction: () => {},
  onPreviewClick: () => {},
  // fromPage: 'regular',
  updateState: () => {},
};

export default ListData;
