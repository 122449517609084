import React from 'react';
import { useParams } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import AddEditSeasons from '../../components/seasons/add-edit-seasons.component';

const ContentSeasonsAddEdit = () => {
  const { seasonId } = useParams();
  const pageTitle = 'Content / Seasons';

  return (
    <AppLayout pageTitle={pageTitle}>
      <AddEditSeasons dataId={seasonId || 0} />
    </AppLayout>
  );
};

export default ContentSeasonsAddEdit;
