import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListFaqs from '../../components/faqs/list-faq';

const FaqsView = () => {
  const pageTitle = 'Content / FAQs';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListFaqs />
    </AppLayout>
  );
};

export default FaqsView;
