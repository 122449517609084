import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListYoutubeLink from '../../components/youtube-link/list-youtube-link';

const YoutubeLinkView = () => {
  const pageTitle = 'Content / Youtube Link';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListYoutubeLink />
    </AppLayout>
  );
};

export default YoutubeLinkView;
