import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Stack, Button, Drawer, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/seasons.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewSeasons from './view-seasons.component';
import { deleteDataById, getListData } from '../../services/seasons.service';
import ROUTE_PATH from '../../config/routes.config';
import existingUrl from '../../utils/url-edit.util';
import UploadAssets from '../common/upload-assets.component';
import FilterSeasons from './filter-seasons.component';
import GeoRestriction from './geo-restriction.component';
import seasonsUrl from '../../utils/url-seasons.util';

const ListSeasons = ({ dataId }) => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;
  const navigate = useNavigate();
  const createSeasonUrl = existingUrl(ROUTE_PATH.CONTENT_SEASONS_NEW, dataId);

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    seriesTitle: '',
    appliedFilters: {
      search: '',
      status: null,
      videoType: null,
    },
    filtersCounter: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [openFilters, setOpenFilters] = useState(false);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    if (options.loading) return;
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    if (selectedAction === ACTIONS.EDIT.value) {
      const url = seasonsUrl(
        ROUTE_PATH.CONTENT_SEASONS_EDIT,
        selectedData.id,
        dataId
      );
      navigate(url);
    } else if (selectedAction === ACTIONS.VIEW_EPISODES.value) {
      navigate(
        `${ROUTE_PATH.CONTENT_VIDEOS}?season=${selectedData?.id || null}`
      );
    }
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };
  const handleActionSuccess = (message = '') => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    if (message) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message,
        });
      }, 100);
    }

    handleActionCancel();
    handleRefreshData();
  };

  const showSnackbarCallback = (type, message) => {
    setSnackbarInfo({ ...snackbarInfo, show: false });
    setTimeout(() => {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type,
        message,
      });
    }, 100);
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteDataById(doAction.data.id)
      .then(() => {
        handleActionSuccess('Season deleted successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleToggleFilters = () => {
    setOpenFilters(!openFilters);
  };

  const handleApplyFilters = (selectedFilters) => {
    let counter = 0;
    if (selectedFilters.search.trim()) counter += 1;
    if (selectedFilters.status !== null) counter += 1;
    if (selectedFilters.videoType !== null) counter += 1;

    setOptions({
      ...options,
      appliedFilters: {
        ...selectedFilters,
      },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    handleToggleFilters();
  };

  const handleClearFilters = () => {
    handleToggleFilters();
    setOptions({
      ...options,
      appliedFilters: {
        search: '',
        status: null,
        videoType: null,
      },
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    const filters = options.appliedFilters;
    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }
    if (filters.status !== null) {
      params.push(`status=${filters.status}`);
    }
    if (filters.videoType !== null) {
      params.push(`videoType=${filters.videoType}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(dataId, paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          seriesTitle: res?.data?.seriesTitle || '',
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '1em',
            alignItems: 'center',
          }}
        >
          <Link to={dataId ? createSeasonUrl : ROUTE_PATH.CONTENT_SEASONS_NEW}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="ic:round-add" />}
            >
              Create New
            </Button>
          </Link>
          <Box fontWeight="bold" fontSize="20px">{`Seasons of ${
            options.seriesTitle ? `- ${options.seriesTitle}` : ''
          }`}</Box>
        </Box>
        <div>
          <Button
            color={options.filtersCounter > 0 ? 'warning' : 'inherit'}
            variant="contained"
            startIcon={<Iconify icon="ic:round-filter-list" />}
            sx={{ marginRight: 2 }}
            onClick={handleToggleFilters}
          >
            Filters
            {options.filtersCounter > 0 ? ` (${options.filtersCounter})` : ''}
          </Button>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            sx={{ marginRight: 2 }}
            disabled={options.loading}
          >
            Refresh
          </Button>
          <Link to={ROUTE_PATH.CONTENT_SERIES}>
            <Button color="primary" variant="contained">
              Back
            </Button>
          </Link>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewSeasons
          title={`Season Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete Season"
          message={`Do you want to delete ${doAction.data.title} season? You can not undo this action!`}
          noteText={
            doAction.data.subscriptionStatus
              ? 'Deleting the user will not delete/cancel the subscriptions assigned to the user. Please notify the user to delete/cancel subscriptions prior account deletion.'
              : ''
          }
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_ASSETS.value && (
        <UploadAssets
          title={`Upload Assets - ${doAction.data.title}`}
          dataId={doAction.data.id}
          dataTitle={doAction.data.title}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          contentType="videos"
          uploadFor="season"
          assetDetail={{
            videoUrl: doAction.data?.videoUrl,
            trailerUrl: doAction.data?.trailerUrl,
            landscapeImage: doAction.data?.landscapeImage,
            portraitImage: doAction.data?.portraitImage,
            videoType: doAction.data?.videoType,
          }}
        />
      )}

      {doAction.action === ACTIONS.GEO_RESTRICTION.value && (
        <GeoRestriction
          dataId={doAction.data.id}
          dataTitle={doAction.data.title}
          onCancel={handleActionCancel}
          showSnackbarCallback={showSnackbarCallback}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
      <Drawer
        anchor="right"
        open={openFilters}
        onClose={handleToggleFilters}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterSeasons
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>
    </>
  );
};

ListSeasons.propTypes = {
  dataId: PropTypes.number.isRequired,
};

export default ListSeasons;
