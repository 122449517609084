import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Validations from '../../utils/validations.util';
import Countries from '../form-elements/countries.component';
import Loading from '../table-elements/loading.component';
import {
  getSeasonAvailability,
  updateSeasonAvailability,
} from '../../services/seasons.service';

const GeoRestriction = ({
  dataId,
  dataTitle,
  onCancel,
  showSnackbarCallback,
}) => {
  const [type, setType] = useState('include');
  const [loading, setLoading] = useState(true);

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      countries: [],
    },
  });

  const submitData = (data) => {
    const payload = {
      type,
      countryCodes: data.countries,
    };
    updateSeasonAvailability(dataId, payload)
      .then((res) => {
        if (res.data.success) {
          showSnackbarCallback(
            'success',
            'Geo Restrication data saved successfully.'
          );
          onCancel();
        }
      })
      .catch((e) => {
        showSnackbarCallback(
          'error',
          e.response?.data?.message ||
            'Something went wrong. Please try again later.'
        );
      });
  };

  useEffect(() => {
    getSeasonAvailability(dataId)
      .then((res) => {
        setType(res.data?.type || 'include');
        setValue('countries', res.data?.countries || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={() => onCancel()}>
      <DialogTitle>{`Geo Restriction - ${dataTitle}`}</DialogTitle>
      <DialogContent dividers>
        {loading && <Loading />}

        {!loading && (
          <form id="manage-availability" onSubmit={handleSubmit(submitData)}>
            <FormControl component="fieldset">
              <p
                style={{
                  marginBottom: '5px',
                  marginTop: 0,
                  fontWeight: 'bold',
                }}
              >
                Select Type
              </p>
              <RadioGroup
                style={{ flexDirection: 'row' }}
                aria-label="type"
                name="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <FormControlLabel
                  value="include"
                  control={<Radio />}
                  label="Include"
                />
                <FormControlLabel
                  value="exclude"
                  control={<Radio />}
                  label="Exclude"
                />
              </RadioGroup>
            </FormControl>
            <p style={{ marginBottom: '15px', fontWeight: 'bold' }}>
              Select Countries
            </p>

            <Controller
              name="countries"
              control={control}
              rules={Validations.REQUIRED}
              render={({ field: { onChange, value } }) => (
                <Countries
                  id="countries"
                  name="countries"
                  label="Countries *"
                  defaultValue={value}
                  multiple
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.countries?.message || ''}
                />
              )}
            />
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          paddingY={0.5}
        >
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="manage-availability"
              sx={{ marginLeft: 2 }}
              disabled={loading}
            >
              Submit
            </Button>

            <Button
              color="secondary"
              variant="contained"
              onClick={onCancel}
              sx={{ marginLeft: 1.5 }}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="secondary">
              * indicates a required field
            </Typography>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

GeoRestriction.propTypes = {
  dataId: PropTypes.number.isRequired,
  dataTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  showSnackbarCallback: PropTypes.func.isRequired,
};

export default GeoRestriction;
