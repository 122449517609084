import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/series/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
export const getSeries = async () => {
  const options = prepareRequestOptions(
    `/admin/series/series-list`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/series/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/series/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/series/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/series/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadImageToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/image/cdn`,
    'POST',
    payload,
    true,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getCountriesList = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/countries/search-list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
