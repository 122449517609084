import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (id, params = '') => {
  let options;
  if (id === 0) {
    options = prepareRequestOptions(
      `/admin/seasons/list${params}`,
      'GET',
      null,
      true
    );
  } else {
    options = prepareRequestOptions(
      `/admin/series/${id}/seasons/list${params}`,
      'GET',
      null,
      true
    );
  }

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/seasons/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/seasons/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/seasons/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/seasons/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadImageToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/image/cdn`,
    'POST',
    payload,
    true,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSeasonAvailability = async (id) => {
  const options = prepareRequestOptions(
    `/admin/season/${id}/availability/get`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateSeasonAvailability = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/season/${id}/availability/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getCountriesList = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/countries/search-list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
