import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

const Datetimepicker = ({
  id,
  name,
  label,
  defaultValue,
  onChange,
  disabled,
  clearable,
  sx,
  error,
}) => {
  const [value, setValue] = useState(defaultValue ? dayjs(defaultValue) : null);

  useEffect(() => {
    setValue(defaultValue ? dayjs(defaultValue) : null);
  }, [defaultValue]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
          id={id}
          name={name}
          label={label}
          value={value}
          onChange={handleChange}
          sx={sx}
          disabled={disabled}
          slotProps={{
            textField: {
              error: error !== '',
              helperText: error,
              variant: 'filled',
            },
            field: { clearable, onClear: () => handleChange(null) },
          }}
          disablePast
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

Datetimepicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  sx: PropTypes.shape(),
  error: PropTypes.string,
};
Datetimepicker.defaultProps = {
  disabled: false,
  clearable: false,
  sx: {},
  defaultValue: null,
  error: '',
};

export default Datetimepicker;
