import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import CommentsConversation from './comments-conversation.component';
import { getListData } from '../../services/videos.service';
import commentsLogo from '../../assets/images/logo/comments-logo.png';
import { PRIMARY } from '../../theme/theme-colors';
import NoData from '../table-elements/no-data.component';
import Loading from '../table-elements/loading.component';

const ListComments = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const searchVideosRef = useRef(null);
  const searchRef = useRef(null);

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });

  const handleScroll = () => {
    setOptions({
      ...options,
      page: options.page + 1,
      reloadCounter: options.reloadCounter + 1,
    });
  };

  const handleSearchChange = (e) => {
    setOptions({ ...options, q: e.target.value || '' });
    if (searchRef.current) clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => {
      setOptions({
        ...options,
        page: 0,
        q: e.target.value || '',
        reloadCounter: options.reloadCounter + 1,
      });
    }, 400);
  };

  const handleSearchVideos = () => {
    setOptions({
      ...options,
      q: searchVideosRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
    });
  };

  useEffect(() => {
    let uri = '';
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    uri += `&videoType=2&cdnFinishedVideo=1&forComment=1`;
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getListData(uri)
      .then((res) => {
        if (options.page === 0) {
          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: res?.data?.rows || [],
            error: false,
          });
        } else {
          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: [...options.rows, ...res.data.rows] || [],
            error: false,
          });
        }
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <Stack direction="row" sx={{ width: '100%' }}>
      <Box
        sx={{
          position: 'relative',
          width: 320,
          height: '78vh',
          backgroundColor: '#F8FAFF',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Stack p={2} sx={{ height: '78vh' }}>
          <TextField
            label="Search Videos"
            type="text"
            inputRef={searchVideosRef}
            onChange={handleSearchChange}
            sx={{
              marginY: 1,
              width: '100%',
              '.MuiInputLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled': {
                top: '0px',
              },
              '.MuiInputLabel-root': {
                top: '-8px',
              },
            }}
            InputProps={{
              style: {
                height: '2.25em',
              },
              endAdornment: (
                <InputAdornment position="end" onClick={handleSearchVideos}>
                  <IconButton edge="end">
                    <Iconify icon="fe:search" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: !(
                searchVideosRef.current?.value === '' ||
                searchVideosRef.current === null
              ),
            }}
          />

          {options.loading && <Loading />}

          {!options.loading && (
            <List
              sx={{
                height: options.rows < options.totalRows ? '98%' : '100%',
                width: '100%',
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                  width: '6px',
                },
                '::-webkit-scrollbar-track': {
                  '-webkit-box-shadow': 'inset 0 0 2px rgba(0, 0, 0, 0)',
                  marginY: '12px',
                },
                '::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  borderRadius: '12px',
                },
                ':hover': {
                  'overflow-y': 'auto',
                },
                ':active': {
                  'overflow-y': 'auto',
                },
                ':focus': {
                  'overflow-y': 'auto',
                },
                textAlign: 'center',
              }}
              spacing={1}
            >
              {options.rows.map((el, index) => (
                <ListItem
                  key={el.id}
                  sx={{
                    marginY: index === 0 || index === options.totalRows ? 0 : 1,
                    cursor: 'pointer',
                    borderRadius: '5px',
                    background: el.id === selectedVideo?.id && PRIMARY.light,
                  }}
                  onClick={() => setSelectedVideo(el)}
                >
                  <ListItemAvatar sx={{ marginRight: '10px' }}>
                    {el.landscapeImage ? (
                      <Box
                        component="img"
                        sx={{
                          objectFit: 'cover',
                          width: 64,
                          height: 36,
                          bgcolor: '#EDEDED',
                          borderRadius: '5px',
                        }}
                        alt={el.title}
                        src={el.landscapeImage}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: 64,
                          height: 36,
                          bgcolor: '#808080',
                          borderRadius: '5px',
                        }}
                      />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                          fontSize: '14px',
                          fontWeight: el.id === selectedVideo?.id && 'bold',
                        }}
                      >
                        {el.title}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}

              {options.rows.length < options.totalRows && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleScroll()}
                  sx={{
                    lineHeight: 0.5,
                    fontSize: '12px',
                    marginTop: '15px',
                    width: 'fit-content',
                  }}
                >
                  Load More
                </Button>
              )}

              {options.rows.length === 0 && <NoData />}
            </List>
          )}
        </Stack>
      </Box>

      <Box
        sx={{
          height: '78vh',
          width: '1150px',
          backgroundColor: '#F8FAFF',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        }}
      >
        {!selectedVideo && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: 1, height: '78vh' }}
          >
            <Box
              component="img"
              src={commentsLogo}
              sx={{ height: 100, mx: 'auto', my: { xs: 1, sm: 1 } }}
            />
            <Typography variant="h4">No video selected</Typography>
          </Stack>
        )}
        {selectedVideo && (
          <CommentsConversation selectedVideo={selectedVideo} />
        )}
      </Box>
    </Stack>
  );
};

export default ListComments;
